<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="container">
		<b-row class="mx-4 mt-4">
			<b-col cols="12">
				<h2 class="text-center">
					{{ stepInfo.msg_title }}
				</h2>
			</b-col>
			<b-col cols="12">
				<img
					:src="logo"
					class="rounded mx-auto d-block"
					:style="{
						width: ['xs', 'sm'].includes(windowWidth) ? '100%' : '60%',
					}"
					alt="logo">
			</b-col>
		</b-row>
		<b-row>
			<b-col
				class="px-4"
				cols="12">
				<div
					class="text-center"
					v-html="welcomeMessage" />
			</b-col>
		</b-row>
	</div>
</template>
<script>
import Onboarding from '@/util/Onboarding';
import { Onboarding as OnboardingMessage } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'WelcomeMessageStep',
	messages: [OnboardingMessage],
	mixins: [WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		stepInfo: {
			type: Object,
			default: () => ({}),
		},
		flowName: {
			type: String,
			default: 'migrated_user_onboarding_distributors_affiliates',
		},
	},
	data() {
		return {
			onboarding: new Onboarding(),
			stepCode: 'welcome_message',
		};
	},
	computed: {
		logo() {
			if (this.isJns()) {
				return require('@/assets/images/themes/Velovita/jns_logo.png'); // eslint-disable-line global-require
			}
			return require('@/assets/images/themes/Velovita/logo.png'); // eslint-disable-line global-require
		},
		welcomeMessage() {
			const mainMessage = this.translate('welcome_message_all');
			return `${mainMessage}`;
		},
	},
};
</script>
